import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import '../generic-page.scss';
import './tier-list.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import { IReverseCharacter } from '../../modules/common/model/graphql-types';
import { faAnglesDown, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Card } from 'react-bootstrap';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { Reverse1999Character } from '../../modules/reverse/common/components/rev-character';
import { RevTagsArray } from '../../modules/reverse/common/components/rev-tags';
import lodash from 'lodash';

interface IReverseCharacterNodes {
  nodes: IReverseCharacter[];
}

interface IReverseCharacterEntry {
  allCharacters: IReverseCharacterNodes;
}

interface IProps {
  data: IReverseCharacterEntry;
}

const REVTierPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);
  const [tags, setTags] = useState('tierListTags');
  const [category, setCategory] = useState('tierListCategory');

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '2✦', value: '2' },
          { label: '3✦', value: '3' },
          { label: '4✦', value: '4' },
          { label: '5✦', value: '5' },
          { label: '6✦', value: '6' }
        ]
      },
      {
        key: 'afflatus',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Star',
            tooltip: 'Star',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_star.png"
                width={24}
                alt="Star"
              />
            )
          },
          {
            value: 'Plant',
            tooltip: 'Plant',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_plant.png"
                width={24}
                alt="Plant"
              />
            )
          },
          {
            value: 'Mineral',
            tooltip: 'Mineral',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_mineral.png"
                width={24}
                alt="Mineral"
              />
            )
          },
          {
            value: 'Beast',
            tooltip: 'Beast',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_beast.png"
                width={24}
                alt="Beast"
              />
            )
          },
          {
            value: 'Spirit',
            tooltip: 'Spirit',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_spirit.png"
                width={24}
                alt="Spirit"
              />
            )
          },
          {
            value: 'Intellect',
            tooltip: 'Intellect',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_intellect.png"
                width={24}
                alt="Intellect"
              />
            )
          }
        ]
      },
      {
        key: 'damageType',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Mental',
            label: 'Mental'
          },
          {
            value: 'Reality',
            label: 'Reality'
          }
        ]
      },
      { key: 'tags', type: 'dropdown', values: RevTagsArray },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (sortOption === 'base') {
      setTags('tierListTags');
      setCategory('tierListCategory');
    }

    if (sortOption === 'euphoria') {
      setTags('tierListTagsEuphoria');
      setCategory('tierListCategoryEuphoria');
    }

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.afflatus && activeFilters.afflatus !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.afflatus.toLowerCase() === activeFilters.afflatus.toLowerCase()
        );
      }
      if (activeFilters.damageType && activeFilters.damageType !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.damageType &&
            emp.damageType.length > 0 &&
            emp.damageType.indexOf(activeFilters.damageType) > -1
        );
      }
      if (activeFilters.tags && activeFilters.tags !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.tags &&
            emp.tags.length > 0 &&
            emp.tags.indexOf(activeFilters.tags) > -1
        );
      }
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: 'base', label: 'Overall' },
    { value: 'euphoria', label: 'Overall (Euphoria)' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page reverse-tier '} game="reverse">
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/reverse/categories/category_tier.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Reverse: 1999 Tier List</h1>
          <h2>
            A tier list for Reverse: 1999 that rates all characters based on
            their Global, CN and Overall performance.
          </h2>
          <p>
            Last updated: <strong>14/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Tier List" />
      <Accordion defaultActiveKey="0" className="tier-list-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>About the Tier List</Accordion.Header>
          <Accordion.Body>
            <p>
              Reverse:1999 is a game where{' '}
              <strong>team building and strategy is the main focus</strong>, so
              take the ratings listed here with a grain of salt, as most
              characters can perform well when under the right team and
              circumstances, while conversely, most characters rely on a good
              synergy with their teammates to deliver a good performance.
            </p>
            <p>
              To build this Tier List, we tested all characters both on their
              lower levels and high level, as well as both in optimal and
              non-optimal scenarios. We took into consideration{' '}
              <strong>
                their versatility, performance compared to other characters of
                the same role/niche, and if they grow or fall off as the game
                progresses
              </strong>
              .
            </p>
            <h6>Tier List Options</h6>
            <ul className="no-colors">
              <li>
                <strong>Overall</strong>: How the character performs ignoring
                the patch specific content - the rating showcases the overall
                character strength,
              </li>
              <li>
                <strong>Overall (Euphoria)</strong>: How the character performs
                ignoring the patch specific content including the impact of
                Euphoria upgrades on the meta - the rating showcases the overall
                character strength.
              </li>
            </ul>
            <h6>Tier List Criteria</h6>
            <ul>
              <li>
                <strong className="dps">Damage Dealer</strong>: Characters whose
                main function is to deal damage.
              </li>
              <li>
                <strong className="sub-dps">Sub Carry</strong>: Characters who
                can both deal damage and help the team in some way.
              </li>
              <li>
                <strong className="support">Support</strong>: Arcanists who you
                bring to the team for their utility or support aspects, be it
                buffs, debuffs, control, and so on.
              </li>
              <li>
                <strong className="sustain">Survival</strong>: Healers, Tanks,
                and the ones with tools who help keep the team alive.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Ratings explained</Accordion.Header>
          <Accordion.Body>
            <ul className="no-margin">
              <li>
                <strong>S+</strong> - characters that are the best at their role
                for this content.
              </li>
              <li>
                <strong>S</strong> - powerful characters that are slightly
                inferior to the S+ ones, but there are some things holding them
                back.
              </li>
              <li>
                <strong>A+</strong> - strong character that perform well that
                still have place in the meta, but require a bit more work to
                shine.
              </li>
              <li>
                <strong>A</strong> - decent characters that are commonly used to
                replace the meta ones when you don't have them or they have some
                niche uses.
              </li>
              <li>
                <strong>B</strong> - average characters that have big flaws and
                only work as some niche replacements when you don't have
                anything else.
              </li>
              <li>
                <strong>C</strong> - characters with very niche kits or that
                struggle due to low stats.
              </li>
              <li>
                <strong>D</strong> - characters with niche kits that are
                difficult to justify, as well as having low stats.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            Changelog <span className="tag updated">Updated!</span>
          </Accordion.Header>
          <Accordion.Body>
            <h6>14.11.2024</h6>
            <p>
              Another update another tier list revamp. It's happened a lot and
              depending on Bluepoch's shenanigans it'll probably happen in the
              future. Such is the nature of tier lists in a PvE gacha game, oh
              well. Let me explain how it goes:
            </p>
            <ul>
              <li>
                All the tier lists are currently condensed into one. The main
                reason being is that Standard wasn't getting looked at at all
                (and kind of redundant). We then looked at Raid, which did
                change based on the patch we were hosting at the time, but it
                slowly became more and more of the same as units like Windsong
                and Lucy released, with only minor shifts depending on new units
                or specific mechanics.
              </li>
              <li>
                Our teambuilding guide is in the process of being updated! This
                will host at least 3 different team options for each Raid boss,
                and the Raid mechanics are covered in the guides themselves; as
                such, we felt like we could drop the Raid tier list as well and
                consolidate all of our tier list data into one "general" tier
                list.
              </li>
              <li>
                In addition, with Global slowly creeping up on the CN timeline,
                the need for a GLB/CN divide is lowered considerably (also
                considering foresight and the potential misinformation that
                comes with balance changes in other servers going forward).
              </li>
              <li>
                This tier list will cover the same points as "Overall" before
                this, taking each character at their base state including some
                team-specific additions such as [Burn] for Spathodea and
                [Upgrade] for Marcus.
              </li>
              <li>
                No changes will be made to what Portraits we look at for
                characters - 6-stars will be considered at P0, 5-stars at P3+
                and the rest at P5.
              </li>
              <li>
                A Euphoria toggle has been added to provide tiering and role
                changes to characters when they unlock them. Considering the
                HEAVY kit changes that come with them, you may find that units
                shift immensely depending on if they have it unlocked or not.
                The community is still analyzing the relative power of Euphoria,
                so adjustments may be made in the future regarding them. Do note
                that characters can have multiple Euphorias (but only have 1
                active at a time), we will consider all characters with their
                most optimal Euphoria and list this in a short description that
                can be found by hovering over the character icon (still WIP).
              </li>
            </ul>
            <h6>27.10.2024</h6>
            <p>
              Additional changes have been made to bring the 'partial' CN and
              Global ratings in line with the recent Overall tier list update.
            </p>
            <h6>23.10.2024</h6>
            <p>New additions (2.2):</p>
            <ul>
              <li>
                <strong>Lopera</strong> Really powerful Crit buffer that can
                grant a lot of [Bullet]s, spam [Burn] and even do some very
                decent healing. Has a really easy Array uptime and functions
                really well in Crit teams. Essentially Mercuria without the need
                for Portraits, though a bit more pigeonholed into niches.
              </li>
            </ul>
            <p>Role Changes:</p>
            <ul>
              <li>
                <strong>Argus</strong> [Sub Carry to Support]. Doesn't really do
                a lot of damage and is primarily used for her debuffing and FUA
                supply.
              </li>
              <li>
                <strong>Isolde</strong> [Support o Sub Carry]. Is primarily used
                for continuously high Mass DMG, FUA spam and Burn, and thus
                functions in a similar way to Shamane.
              </li>
            </ul>
            <p>Rating Changes:</p>
            <ul>
              <li>
                <strong>Avgust</strong> [From A+ to A]. Provides a lot of stats,
                both offensively and defensively, but doesn't do much besides
                that.
              </li>
              <li>
                <strong>Bkornblume</strong> [From A to A+]. Decent replacement
                for common debuffers with high personal damage output.
              </li>
              <li>
                <strong>Dikke</strong> [From B to C]. Cannot really function as
                a healer unless used under very specific circumstances.
              </li>
              <li>
                <strong>Erick</strong> [From C to D].
              </li>
              <li>
                <strong>Isolde</strong> [From S+ to S]. Falls much closer to
                other Sub Carries now that other Supports have been released.
                Still very powerful, but not the unbeatable powerhouse she was
                during her update.
              </li>
              <li>
                <strong>J</strong> [From S to A+]. Very powerful in the right
                scenarios but still rough in terms of use cases, doesn't
                typically stack up against the higher ranked Sub Carries.
              </li>
              <li>
                <strong>Kanjira</strong> [From B to C]. Is locked into a very
                specific rotation that makes it hard to really deviate and set
                up for potential bursts from the main Damage Dealer.
              </li>
              <li>
                <strong>La Source</strong> [From B to C].
              </li>
              <li>
                <strong>Lorelei</strong> [From S to A+]. Functions similarly to
                characters like Mercuria and Lopera, however, lacking some of
                the ridiculous values that come with the other two.
              </li>
              <li>
                <strong>Regulus</strong> [From A+ to A]. While maintaining her
                position as probably one of the greatest defensive Ultimates,
                plus buffs on the horizon, for now she remains lackluster in her
                role as Sub Carry.
              </li>
              <li>
                <strong>Tooth Fairy</strong> [From S+ to S]. Before we get
                angry, TF moving down from S+ to S is not a big deal. She still
                has immense healing output and consistent Crit debuffs, and
                she's moved the entire Survival tier one down below her. She has
                been meta since 1.2, but her kit is starting to show its age
                against the new powerhouses in Kakania and Vila; in other words,
                she's taking a step back to let the kids have their fun.
              </li>
              <li>
                <strong>Ulu</strong> [From C to D]. With other [Burn]
                applicants, using Ulu is mostly a waste of space.
              </li>
              <li>
                <strong>Yenisei</strong> [From S to A+]. Her defensive output
                roughly matches that of Medicine Pocket and Tooth Fairy,
                however, Tooth Fairy has access to offensive support whereas
                Yenisei is locked entirely into defense.
              </li>
            </ul>
            <h6>30.09.2024</h6>
            <p>New additions (2.2):</p>
            <ul>
              <li>
                <strong>Anjo Nala</strong> [S+/S+/S+] Sub Carry. The Queen of
                Versatility, Anjo Nala's [Contract]s allow her to fit into any
                team and her combination of high personal damage output and
                crowd control allow her to fit into any type of content.
              </li>
              <li>
                <strong>Mr. Duncan</strong> [A/A/A] Damage Dealer. Similar in
                function to J, Duncan provides a slew of counters with
                additional effects based on his [Bullet] while accumulating
                [Ceremonious] to unleash a big Ultimate. Unfortunately, he has
                to compete with absolute damage monsters in the higher ranks,
                and as a 5-star he simply doesn't compete as well (though better
                than most!).
              </li>
              <li>
                <strong>White Rum</strong> [B/B/A] Sub Carry. One of the better
                4-star units, White Rum has access to very consistent FUAs, self
                buffing and what looks to be our first true Mass [Daze] (with a
                100% Resistance Penetration at P4). Works great in teams with 37
                or in CC teams.
              </li>
            </ul>
            <h6>18.09.2024</h6>
            <p>New additions (1.9):</p>
            <ul>
              <li>
                <strong>Lucy</strong> [S+/S+/S+]. One half of the top Damage
                Dealers in the game. Lucy's Afflatus, [Pragmatist] buff and
                varied options for damage allows her to fit into pretty much any
                team and piece of content in the game.
              </li>
              <li>
                <strong>Kakania</strong> [S+/S/S+]. The first proper Tank in the
                game (can redirect damage taken by allies) and at the same time
                an extremely powerful Support with [Solace] and Damage Dealer
                with her Insights.
              </li>
              <li>
                <strong>Semmelweis</strong> [A+/A+/A+]. The game's first foray
                into directly benefitting from HP Drain, Semmelweis can provde a
                LOT of DMG Bonus and Crit Rate to her allies, assuming they can
                survive.
              </li>
              <li>
                <strong>Lorelei</strong> [S/S/S]. The Sonetto of Year 2, Lorelei
                provides passive DMG Dealt buffs, Crit DMG and also enhances the
                team's survivability with various defensive (de)buffs.
              </li>
            </ul>
            <p>Standard changes (1.9):</p>
            <ul>
              <li>
                <strong>Jiu Niangzi</strong> [From S+ to S]. The arrival of Lucy
                and the new Tuning for Windsong places those two well above Jiu
                Niangzi. She remains the Queen of FUA and has incredibly
                consistent damage, but she now falls closer to characters like
                Marcus and Spathodea.
              </li>
              <li>
                <strong>Melania</strong> [From S to A+]. Unfortunately, due to
                Mental Teams not really getting anything in recent patches, and
                the damage ceiling slowly rising with Windsong and Lucy, Melania
                struggles to hold on as Moxie and Ult spam are the things that
                keep her relevant.
              </li>
              <li>
                <strong>A Knight</strong> [From A+ to A]. Used to be the premier
                Primal Afflatus Damage Dealer (and the only relevant one), he is
                pushed out of his role as a spammy Mass Carry and general use
                Damage Dealer by Lucy due to his perfectly average gameplay,
                something that is considered mediocre in the realm after 1.9.
              </li>
              <li>
                <strong>Satsuki</strong> [From A to B]. Still a relevant Carry
                for Pertrify teams however, due to Kakania's release, Crowd
                Control teams veer more so into the area of things like Druvis /
                An-An Lee / Kakania.
              </li>
              <li>
                <strong>An-An Lee</strong> [From S to A+]. Simply just doesn't
                do as much as newer supports and will generally get beat out by
                characters like Lorelei and 6 who have more offensive support
                options as well as defensive ones.
              </li>
              <li>
                <strong>Voyager</strong> [From A+ to A]. An amazing (albeit
                ap-negative) defensive Support, Voyager nowadays is mostly
                relegated to hyper Crowd Control comps like Druvis / An-An Lee /
                Voyager / Kakania.
              </li>
              <li>
                <strong>Pickles</strong> [From A+ to A]. Kind of similar in
                reasoning to An-An Lee, all Pickles really has going for him is
                his ap-positive playstyle and the ability to full-dispel on a
                basic Incantation (under the right circumstances).
              </li>
              <li>
                <strong>Sonetto</strong> [From A to B]. Overshadowed by Lorelei
                due to both being free units. Sonetto remains useful for her
                high personal damage and somewhat consistent access to [Disarm],
                but not much beyond that.
              </li>
              <li>
                <strong>Erick</strong> [From B to C]. Due to the amount of Crit
                Support we've had over the months Erick really has no reason to
                be used beyond maybe low-star content or aid unit in Roguelite.
              </li>
            </ul>
            <p>Raid changes (1.9):</p>
            <ul>
              <li>
                <strong>Jiu Niangzi</strong> [From S+ to S]. Great in Opera and
                Nightmare, unusable in Gorgon due to incompatible kit.
              </li>
              <li>
                <strong>Melania</strong> [From S to A+]. Great in Opera, okay in
                Nightmare and unusable in Gorgon due to incompatible kit.
              </li>
              <li>
                <strong>A Knight</strong> [From A to A+]. Does great in
                Nightmare due to his spammy Mass attacks.
              </li>
              <li>
                <strong>37</strong> [From A+ to S]. Amazing in Gorgon and
                Nightmare, passable in Opera if used in combination with
                Windsong, Jiu Niangzi or Lucy.
              </li>
              <li>
                <strong>Shamane</strong> [From A+ to A]. Doesn't really do all
                that great in any of the raids but can do decent in Nightmare.
              </li>
              <li>
                <strong>Medicine Pocket</strong> [From S to A+]. Great in Opera
                and decent in Nightmare but unusable in Gorgon due to
                incompatible kit.
              </li>
            </ul>
            <h6>02.09.2024</h6>
            <p>
              We have fully reworked the tier list. Read more on our{' '}
              <a
                href="https://blog.prydwen.gg/2024/09/02/reverse-1999-complete-tier-list-overhaul/"
                target="_blank"
                rel="noreferrer"
              >
                blog
              </a>
              .
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="tier-list-header">
        <p className="title">
          Tier List{' '}
          <span>
            ({sortOption === 'base' && 'Overall'}
            {sortOption === 'euphoria' && 'Overall - Euphoria'})
          </span>
        </p>
        <p className="sub-title">
          You're currently viewing the{' '}
          <strong>
            {sortOption === 'base' && 'Overall'}
            {sortOption === 'euphoria' && 'Overall - Euphoria'}
          </strong>{' '}
          tier list. It shows{' '}
          {sortOption === 'base' &&
            'how the character performs ignoring the patch specific content - the rating showcases the overall character strength.'}{' '}
          {sortOption === 'euphoria' &&
            'how the character performs ignoring the patch specific content - the rating showcases the overall character strength including the Euphoria upgrades.'}{' '}
          Use the switcher below to view a different tier list.
        </p>
      </div>
      <div className="tier-list-switcher">
        <div
          className={`option overall ${sortOption === 'base' && 'selected'}`}
          onClick={() => setSortOption('base')}
        >
          <p>Overall</p>
        </div>
        <div
          className={`option standard ${
            sortOption === 'euphoria' && 'selected'
          }`}
          onClick={() => setSortOption('euphoria')}
        >
          <p>Overall (Euphoria)</p>
        </div>
      </div>
      <div className="employees-filter-bar">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="custom-tier-list-reverse">
        <div className="custom-tier-header">
          <div className="tier-rating">
            <span>&nbsp;</span>
          </div>
          <div className="custom-tier-container">
            <div className="custom-header dps">Damage dealer</div>
            <div className="custom-header sub-dps">Sub Carry</div>
            <div className="custom-header support">Support</div>
            <div className="custom-header survival">Survival</div>
          </div>
        </div>
        <div className={`meta-line`}>
          <h5 className="meta-header meta">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Best In Role{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-s first">
          <div className="tier-rating s-plus">
            <span>S+</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 10)
                .filter((emp) => emp[category] === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 10)
                .filter((emp) => emp[category] === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 10)
                .filter((emp) => emp[category] === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 10)
                .filter((emp) => emp[category] === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line`}>
          <h5 className="meta-header top">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Top Picks{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-s">
          <div className="tier-rating s">
            <span>S</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 9)
                .filter((emp) => emp[category] === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 9)
                .filter((emp) => emp[category] === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 9)
                .filter((emp) => emp[category] === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 9)
                .filter((emp) => emp[category] === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
        <div className="custom-tier tier-a-plus">
          <div className="tier-rating a-plus">
            <span>A+</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 8)
                .filter((emp) => emp[category] === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 8)
                .filter((emp) => emp[category] === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 8)
                .filter((emp) => emp[category] === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 8)
                .filter((emp) => emp[category] === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line`}>
          <h5 className="meta-header alt">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Common
            Replacements <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-a">
          <div className="tier-rating a">
            <span>A</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 7)
                .filter((emp) => emp[category] === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 7)
                .filter((emp) => emp[category] === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 7)
                .filter((emp) => emp[category] === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 7)
                .filter((emp) => emp[category] === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="custom-tier tier-b">
          <div className="tier-rating b">
            <span>B</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 6)
                .filter((emp) => emp[category] === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 6)
                .filter((emp) => emp[category] === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 6)
                .filter((emp) => emp[category] === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 6)
                .filter((emp) => emp[category] === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
        <div className={`meta-line`}>
          <h5 className="meta-header niche">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Niche Picks{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-c">
          <div className="tier-rating c">
            <span>C</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 5)
                .filter((emp) => emp[category] === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 5)
                .filter((emp) => emp[category] === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 5)
                .filter((emp) => emp[category] === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 5)
                .filter((emp) => emp[category] === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="custom-tier tier-e">
          <div className="tier-rating d">
            <span>D</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 4)
                .filter((emp) => emp[category] === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 4)
                .filter((emp) => emp[category] === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 4)
                .filter((emp) => emp[category] === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 4)
                .filter((emp) => emp[category] === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default REVTierPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Reverse: 1999 | Prydwen Institute"
    description="A tier list for Reverse: 1999 that rates all characters based on their Global, CN and Overall performance."
    game="reverse"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulReverseCharacter(
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        tierListCategory
        tierListTags
        tierListCategoryEuphoria
        tierListTagsEuphoria
        rarity
        afflatus
        damageType
        tags
        ratingsNew {
          base
          euphoria
        }
        tierComment {
          tierComment
        }
        releasePatch
        availableInGlobal
        isReviewPending
        isNew
      }
    }
  }
`;
